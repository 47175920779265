import { useGoogleLogin } from "@react-oauth/google";
import { FC } from "react";
import { useAppDispatch } from "../app/store";
import { login } from "../features/account/accountSlice";
import { Button } from "@mui/material";

export const LoginButton: FC = () => {
    const dispatch = useAppDispatch();

    const googleLogin = useGoogleLogin({
        onError: () => console.log("Error"),
        onSuccess: (codeResponse) => dispatch(login(codeResponse.code)),
        flow: "auth-code",
    });

    return <Button onClick={googleLogin}>Logga in</Button>;
};
