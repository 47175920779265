import { TextField, TextFieldProps } from "@mui/material";
import { useEffect, useState } from "react";

type Props<T> = {
    debounce?: number;
    onChange: (newValue: T) => void;
    value: T;
} & Omit<TextFieldProps, "onChange" | "value">;

export function DebouncedInput<T>({
    debounce,
    onChange,
    value,
    ...props
}: Props<T>) {
    const [input, setInput] = useState<T>(value);

    useEffect(() => {
        const debounceTimer = setTimeout(
            () => onChange(input),
            debounce ?? 500
        );

        return () => {
            clearTimeout(debounceTimer);
        };
    }, [debounce, input]);

    return (
        <TextField
            {...props}
            onChange={(event) => setInput(event.target.value as T)}
            value={input}
        />
    );
}
