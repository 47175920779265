import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Page } from "../../components/Page";
import { setTitle } from "../header/headerSlice";
import { setDocumentTitle } from "../../utils/setDocumentTitle";

export const NotFoundPage: FC = () => {
    const { t } = useTranslation("notFoundPage");
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setTitle(t("title")));
        setDocumentTitle(t("title"));
    }, [dispatch, t]);

    return <Page>{t("pageNotFound")}</Page>;
};
