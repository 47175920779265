import { CssBaseline } from "@mui/material";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LoginPage } from "./features/account/LoginPage";
import { setAccountId, setJwt } from "./features/account/accountSlice";
import { CollectionPage } from "./features/collections/CollectionPage";
import { CollectionsPage } from "./features/collections/CollectionsPage";
import { Header } from "./features/header/Header";
import { HomePage } from "./features/home/HomePage";
import { InventoryPage } from "./features/inventory/InventoryPage";
import { NotFoundPage } from "./features/not-found/NotFoundPage";
import { CardPage } from "./pages/card-page/CardPage";
import { BrowseCardsPage } from "./pages/cards-page/BrowseCardsPage";
import { ExpansionsPage } from "./pages/expansions-page/ExpansionsPage";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AccountPage } from "./pages/account-page/AccountPage";
import { ExpansionPage } from "./pages/expansion-page/ExpansionPage";

export const App: FC = () => {
    const dispatch = useDispatch();

    const jwt = localStorage.getItem("jwt");
    const accountId = parseInt(localStorage.getItem("accountId") ?? "", 10);

    if (jwt) {
        dispatch(setJwt(jwt));
    }

    if (!isNaN(accountId)) {
        dispatch(setAccountId(accountId));
    }

    return (
        <GoogleOAuthProvider clientId="574837096834-v4ofutfbtnc58neho1pq1oocn2mdee4g.apps.googleusercontent.com">
            <BrowserRouter>
                <CssBaseline />
                <Header />
                <Routes>
                    <Route Component={HomePage} path="/" />
                    <Route Component={BrowseCardsPage} path="/cards" />
                    <Route Component={ExpansionsPage} path="/expansions" />
                    <Route
                        Component={ExpansionPage}
                        path="/expansion/:expansionId"
                    />
                    <Route Component={AccountPage} path="/account" />
                    <Route Component={CollectionsPage} path="/my-collections" />
                    <Route Component={InventoryPage} path="/my-cards" />
                    <Route
                        Component={CollectionPage}
                        path="/collections/:collectionId"
                    />
                    <Route Component={LoginPage} path="/login" />
                    <Route
                        Component={CardPage}
                        path="/card/:cardId/:cardName"
                    />
                    <Route Component={NotFoundPage} />
                </Routes>
            </BrowserRouter>
        </GoogleOAuthProvider>
    );
};
