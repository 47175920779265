import {
    CardVariant,
    GetInventoryCardResponse,
} from "@ekdennisek/pricetracker-api";
import { Add, Remove } from "@mui/icons-material";
import {
    Box,
    Card,
    CardContent,
    IconButton,
    InputAdornment,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    useGetInventoryCardQuery,
    useUpdateInventoryQuantityMutation,
} from "../../features/collections/collectionsApi";
import { conditionToEmoji } from "../../utils/conditionToEmoji";

export const OwnedByMe: FC<{ cardId: string; cardVariants: CardVariant[] }> = ({
    cardId,
    cardVariants,
}) => {
    const { t } = useTranslation("cardPage");

    const [activeTab, setActiveTab] = useState<CardVariant>("standard");

    const { data: inventoryCard } = useGetInventoryCardQuery(cardId);
    const [updateInventoryQuantity] = useUpdateInventoryQuantityMutation();

    if (!inventoryCard) return null;

    const handleUpdateQuantity = async (
        cardVariant: CardVariant,
        condition: number,
        newQuantity: number
    ) => {
        await updateInventoryQuantity({
            cardId,
            cardVariant,
            condition,
            quantity: newQuantity,
        });
    };

    return (
        <Card sx={{ height: "100%" }}>
            <CardContent>
                <Stack spacing={3}>
                    <Typography fontWeight={500}>
                        {t("inventory.numCardsInInventory", {
                            numCards: sumQuantity(inventoryCard),
                        })}
                    </Typography>
                    <Typography>
                        {t("inventory.numCardsByCondition")}
                    </Typography>
                    <Tabs
                        value={activeTab}
                        onChange={(_, newValue) => setActiveTab(newValue)}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {cardVariants.map((cardVariant) => (
                            <Tab
                                key={cardVariant}
                                value={cardVariant}
                                label={t(`cardVariant:${cardVariant}`)}
                            />
                        ))}
                    </Tabs>
                    {cardVariants.map(
                        (cardVariant) =>
                            cardVariant === activeTab && (
                                <Box key={cardVariant} sx={{ px: 3 }}>
                                    <Stack spacing={3}>
                                        {[5, 4, 3, 2, 1].map((condition) => (
                                            <ConditionQuantity
                                                condition={condition}
                                                key={condition}
                                                onUpdateQuantity={(
                                                    newQuantity
                                                ) =>
                                                    handleUpdateQuantity(
                                                        cardVariant,
                                                        condition,
                                                        newQuantity
                                                    )
                                                }
                                                quantity={sumQuantity(
                                                    inventoryCard,
                                                    cardVariant,
                                                    condition
                                                )}
                                            />
                                        ))}
                                    </Stack>
                                </Box>
                            )
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
};

const ConditionQuantity: FC<{
    condition: number;
    onUpdateQuantity: (newQuantity: number) => void;
    quantity: number;
}> = ({ condition, onUpdateQuantity, quantity }) => {
    return (
        <Box sx={{ alignItems: "center", display: "flex" }}>
            <Typography sx={{ mr: 1, my: 0.5 }}>
                {conditionToEmoji(condition)}
            </Typography>
            <TextField
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => onUpdateQuantity(quantity - 1)}
                            >
                                <Remove />
                            </IconButton>
                            <IconButton
                                onClick={() => onUpdateQuantity(quantity + 1)}
                            >
                                <Add />
                            </IconButton>
                        </InputAdornment>
                    ),
                    readOnly: true,
                }}
                value={quantity}
                variant="standard"
            />
        </Box>
    );
};

function sumQuantity(
    cards: GetInventoryCardResponse,
    cardVariant?: CardVariant,
    condition?: number
) {
    return cards
        .filter(
            (card) =>
                (!cardVariant || card.cardVariant === cardVariant) &&
                (!condition || card.condition === condition)
        )
        .reduce((acc, cur) => (acc += cur.quantity), 0);
}
