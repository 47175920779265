import { useState } from "react";

export function useDialog<T>(initialData?: T) {
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState<T | undefined>(initialData);

    const close = () => {
        setIsOpen(false);
    };

    const open = () => {
        setIsOpen(true);
    };

    return {
        close,
        data,
        isOpen,
        open,
        setData,
    };
}
