import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { App } from "./App";
import { store } from "./app/store";
import { initI18n } from "./i18n";
import { ThemeProvider, createTheme } from "@mui/material/styles";

initI18n();

const theme = createTheme({
    palette: {
        background: {
            default: "#A3A3A3",
            paper: "#C9C9C9",
        },
        primary: {
            main: "#8B1E3F",
            light: "#a86d7f",
            dark: "#6b142f",
            contrastText: "#FEFFEA",
        },
        secondary: {
            main: "#8E9AAF",
        },
    },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </Provider>
    </React.StrictMode>
);
