import {
    Box,
    Card,
    CardContent,
    Divider,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/store";
import { Link } from "../../components/Link";
import { LoginButton } from "../../components/LoginButton";
import { Page } from "../../components/Page";
import { setDocumentTitle } from "../../utils/setDocumentTitle";
import { selectIsAuthenticated } from "../account/accountSlice";
import { setTitle } from "../header/headerSlice";

export const HomePage: FC = () => {
    const { t } = useTranslation("homePage");
    const dispatch = useAppDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);

    useEffect(() => {
        dispatch(setTitle(t("title")));
        setDocumentTitle(t("title"));
    }, [dispatch, t]);

    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up("md"));

    return (
        <Page>
            <Stack spacing={2}>
                <Stack
                    direction={desktop ? "row" : "column"}
                    spacing={desktop ? 8 : 2}
                >
                    <Box>
                        <Typography variant="h3">
                            För dig som vill hålla koll på din kortsamling
                        </Typography>
                        <Typography>
                            Kortbanken är stället för dig som snabbt och enkelt
                            vill kunna överblicka vad du har i dina lådor - utan
                            att faktiskt öppna dem. Skapa samlingsmål, dela
                            foton med andra och mycket mer!
                        </Typography>
                    </Box>
                    {!isAuthenticated && (
                        <Card>
                            <CardContent>
                                <Stack spacing={2}>
                                    <Typography variant="h6">
                                        Logga in med Google för att hantera dina
                                        kort.
                                    </Typography>
                                    <LoginButton />
                                </Stack>
                            </CardContent>
                        </Card>
                    )}
                </Stack>
                <Divider />
                <Typography variant="h6">Bläddra bland korten</Typography>
                <Link href="/cards">
                    Klicka här för att komma till en lista över kort
                </Link>
            </Stack>
        </Page>
    );
};
