import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { CollectionForm } from "./CollectionForm";
import { useCreateCollectionMutation } from "./collectionsApi";

type Props = {
    onClose: () => void;
    open: boolean;
};

export const CreateCollectionDialog: FC<Props> = ({ onClose, open }) => {
    const [collectionName, setCollectionName] = useState("");
    const [publicVisibility, setPublicVisibility] = useState(true);

    const [createCollection, createCollectionMutation] =
        useCreateCollectionMutation();

    const { t } = useTranslation("collectionsPage");

    const handleCreate = async () => {
        if (!collectionName) return;

        await createCollection({
            collectionName,
            publicVisibility,
        });

        setCollectionName("");
        setPublicVisibility(true);
        onClose();
    };

    return (
        <Dialog fullWidth onClose={onClose} open={open}>
            <DialogTitle>{t("createCollectionDialog.title")}</DialogTitle>
            <DialogContent>
                <CollectionForm
                    onChange={(arg) => {
                        setCollectionName(arg.collectionName);
                        setPublicVisibility(arg.publicVisibility);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    {t("createCollectionDialog.cancel")}
                </Button>
                <Button
                    disabled={createCollectionMutation.isLoading}
                    onClick={handleCreate}
                >
                    {t("createCollectionDialog.create")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
