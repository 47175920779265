import { BaseQueryFn } from "@reduxjs/toolkit/query/react";
import axios, { AxiosRequestConfig } from "axios";
import { RootState } from "../app/store";

const instance = axios.create({ baseURL: "/api" });

export async function get<T>(url: string, config?: Config) {
    const response = await instance.get<T>(url, getAxiosConfig(config));
    return response.data;
}

export async function post<T>(url: string, data?: unknown, config?: Config) {
    const response = await instance.post<T>(url, data, getAxiosConfig(config));
    return response.data;
}

function getAxiosConfig(config: Config | undefined): AxiosRequestConfig {
    if (!config) {
        return {};
    }

    return {
        headers: getAuthHeaders(config.jwt),
        params: config.params,
    };
}

function getAuthHeaders(jwt: string | undefined) {
    if (!jwt) {
        return undefined;
    }
    return { Authorization: `Bearer ${jwt}` };
}

type Config = {
    jwt?: string;
    params?: unknown;
};

export const axiosBaseQuery =
    ({
        baseUrl,
    }: {
        baseUrl: string;
    }): BaseQueryFn<
        {
            url: string;
            method: AxiosRequestConfig["method"];
            data?: AxiosRequestConfig["data"];
            params?: AxiosRequestConfig["params"];
        },
        unknown,
        unknown
    > =>
    async ({ method, url, data, params }, { getState }) => {
        try {
            const jwt = (getState() as RootState).account.jwt;
            const result = await instance({
                url: baseUrl + url,
                method,
                data,
                params,
                headers: {
                    Authorization: jwt ? `Bearer ${jwt}` : undefined,
                    "Content-Type": "application/json",
                },
            });
            return { data: result.data };
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    error: {
                        status: error.code,
                        data: error.message,
                    },
                };
            }
            throw error;
        }
    };
