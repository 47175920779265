import { LinkProps, Link as MuiLink } from "@mui/material";
import * as React from "react";
import { FC, PropsWithChildren } from "react";
import { Link as RouterLink } from "react-router-dom";

export const Link: FC<LinkProps & PropsWithChildren> = ({
    children,
    href,
    ...props
}) => {
    return (
        <MuiLink
            component={RouterLink}
            to={href}
            sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                ...props.sx,
            }}
            {...props}
        >
            {children}
        </MuiLink>
    );
};
