import {
    GetCardResponse,
    GetCardsResponse,
} from "@ekdennisek/pricetracker-api";
import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../utils/restApi";

export const cardApi = createApi({
    reducerPath: "cardApi",
    baseQuery: axiosBaseQuery({
        baseUrl: "/cards",
    }),
    endpoints: (builder) => ({
        getCard: builder.query<GetCardResponse, string>({
            query: (data) => ({
                url: `/${data}`,
                method: "GET",
            }),
        }),
        getCards: builder.query<
            GetCardsResponse,
            { expansionIds?: string[]; page?: number; q?: string }
        >({
            query: ({ expansionIds, page, q }) => ({
                url: "",
                method: "GET",
                params: {
                    expansionIds: (expansionIds ?? []).join(","),
                    page,
                    q,
                },
            }),
        }),
    }),
});

export const { useGetCardQuery, useGetCardsQuery } = cardApi;
