import { configureStore } from "@reduxjs/toolkit";
import headerReducer from "../features/header/headerSlice";
import accountReducer from "../features/account/accountSlice";
import { useDispatch } from "react-redux";
import { collectionsApi } from "../features/collections/collectionsApi";
import { cardApi } from "../features/card/cardApi";
import { expansionsApi } from "../features/expansions/expansionsApi";
import { accountApi } from "../features/account/accountApi";

export const store = configureStore({
    reducer: {
        header: headerReducer,
        account: accountReducer,
        [accountApi.reducerPath]: accountApi.reducer,
        [cardApi.reducerPath]: cardApi.reducer,
        [collectionsApi.reducerPath]: collectionsApi.reducer,
        [expansionsApi.reducerPath]: expansionsApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([
            accountApi.middleware,
            cardApi.middleware,
            collectionsApi.middleware,
            expansionsApi.middleware,
        ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => AppDispatch = useDispatch;
