import { GetAccountCollectionsResponse } from "@ekdennisek/pricetracker-api";
import { FormControlLabel, FormGroup, Switch, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
    collection?: GetAccountCollectionsResponse[0];
    onChange: (arg: {
        collectionName: string;
        publicVisibility: boolean;
    }) => void;
};

export const CollectionForm: FC<Props> = ({ collection, onChange }) => {
    const { t } = useTranslation("collectionsPage");

    const [collectionName, setCollectionName] = useState(
        collection?.collectionName ?? ""
    );
    const [publicVisibility, setPublicVisibility] = useState(
        collection?.publicVisibility ?? true
    );

    useEffect(() => {
        setCollectionName(collection?.collectionName ?? "");
        setPublicVisibility(collection?.publicVisibility ?? true);
    }, [collection?.collectionName, collection?.publicVisibility]);

    useEffect(() => {
        onChange({ collectionName, publicVisibility });
    }, [collectionName, publicVisibility]);

    return (
        <>
            <FormGroup>
                <TextField
                    label={t("collectionForm.collectionName")}
                    margin="dense" // So that the label isn't cut off
                    onChange={(event) => setCollectionName(event.target.value)}
                    value={collectionName}
                />
            </FormGroup>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Switch
                            checked={publicVisibility}
                            onChange={(event) =>
                                setPublicVisibility(event.target.checked)
                            }
                        />
                    }
                    label={t("collectionForm.publicVisibility")}
                    labelPlacement="start"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        ml: 0,
                    }}
                />
            </FormGroup>
        </>
    );
};
