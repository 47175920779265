import { FC, useEffect } from "react";
import { Page } from "../../components/Page";
import { useAppDispatch } from "../../app/store";
import { setTitle } from "../../features/header/headerSlice";
import { setDocumentTitle } from "../../utils/setDocumentTitle";
import { useTranslation } from "react-i18next";
import { useGetSummaryQuery } from "../../features/account/accountApi";
import { Typography } from "@mui/material";

export const AccountPage: FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("accountPage");
    const { data: summary } = useGetSummaryQuery();

    useEffect(() => {
        dispatch(setTitle(t("title")));
        setDocumentTitle(t("title"));
    }, [dispatch, t]);

    if (!summary) return null;

    return (
        <Page>
            <Typography>
                {t("numPublicCollections")}: {summary.collections.numPublic}
            </Typography>
            <Typography>
                {t("numPrivateCollections")}: {summary.collections.numPrivate}
            </Typography>
            <Typography>
                {t("numCards")}: {summary.cards.numCards}
            </Typography>
            <Typography>
                {t("numDistinctExpansions")}:{" "}
                {summary.cards.numDistinctExpansions}
            </Typography>
        </Page>
    );
};
