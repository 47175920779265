import { CardVariant, GetCardsResponse } from "@ekdennisek/pricetracker-api";
import { MoreHoriz, Photo } from "@mui/icons-material";
import {
    Box,
    Checkbox,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { AddToCollectionDialog } from "../../components/AddToCollectionDialog";
import { useAddCardToCollectionMutation } from "../../features/collections/collectionsApi";

type Props = {
    cards: GetCardsResponse["cards"];
    from: number;
    numTotalCards: number;
    to: number;
};

export const CardsTable: FC<Props> = ({ cards, from, numTotalCards, to }) => {
    const navigate = useNavigate();
    const { t } = useTranslation("browseCardsPage");

    const [selectedCards, setSelectedCards] = useState<string[]>([]);
    const [moreMenuOpen, setMoreMenuOpen] = useState(false);
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(
        null
    );
    const [addToCollectionDialogOpen, setAddToCollectionDialogOpen] =
        useState(false);

    const [addCardToCollection] = useAddCardToCollectionMutation();

    useEffect(() => {
        // Use "from" as a proxy for when the user navigates to a new page
        setSelectedCards([]);
    }, [from]);

    const handleCheckboxChange = (cardId: string, checked: boolean) => {
        if (checked) {
            setSelectedCards((prev) => [...prev, cardId]);
        } else {
            setSelectedCards((prev) => prev.filter((c) => c !== cardId));
        }
    };

    const handleAddToCollection = async ({
        collectionId,
        cardVariant,
        condition,
        quantity,
    }: {
        collectionId: number;
        cardVariant: CardVariant;
        condition: number;
        quantity: number;
    }) => {
        await addCardToCollection({
            cardIds: selectedCards,
            collectionId,
            cardVariant,
            condition,
            quantity,
        });
    };

    return (
        <>
            <Typography>
                {t("showing", { from, to, total: numTotalCards })}
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <IconButton
                                    onClick={(event) => {
                                        setAnchorElement(event.currentTarget);
                                        setMoreMenuOpen(true);
                                    }}
                                >
                                    <MoreHoriz />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorElement}
                                    onClose={() => setMoreMenuOpen(false)}
                                    open={moreMenuOpen}
                                >
                                    <MenuItem
                                        onClick={() => {
                                            setMoreMenuOpen(false);
                                            setAddToCollectionDialogOpen(true);
                                        }}
                                    >
                                        {t("table.addToCollection")}
                                    </MenuItem>
                                </Menu>
                                <AddToCollectionDialog
                                    onAdd={handleAddToCollection}
                                    onClose={() =>
                                        setAddToCollectionDialogOpen(false)
                                    }
                                    open={addToCollectionDialogOpen}
                                />
                            </TableCell>
                            <TableCell>{t("table.image")}</TableCell>
                            <TableCell>{t("table.cardName")}</TableCell>
                            <TableCell>{t("table.expansionName")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cards.map((card) => (
                            <TableRow
                                key={card.cardId}
                                onClick={() =>
                                    navigate(
                                        `/card/${
                                            card.cardId
                                        }/${encodeURIComponent(card.cardName)}`
                                    )
                                }
                                sx={{
                                    ":hover": {
                                        backgroundColor: "primary.light",
                                        cursor: "pointer",
                                    },
                                }}
                            >
                                <TableCell width={4}>
                                    <Checkbox
                                        onChange={(_, checked) =>
                                            handleCheckboxChange(
                                                card.cardId,
                                                checked
                                            )
                                        }
                                        onClick={(event) =>
                                            event.stopPropagation()
                                        }
                                        value={selectedCards.includes(
                                            card.cardId
                                        )}
                                    />
                                </TableCell>
                                <TableCell width={4}>
                                    <Tooltip
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            // TODO Öppna fullstor bild i ny flik?
                                            console.log("card");
                                        }}
                                        title={
                                            <img
                                                alt={card.cardName}
                                                src={card.thumbnail}
                                            />
                                        }
                                    >
                                        <Photo />
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    {card.cardName} ({card.cardNumber}/
                                    {card.printedTotal})
                                </TableCell>
                                <TableCell>
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        flexDirection="row"
                                    >
                                        <img
                                            alt={card.expansionName}
                                            height={16}
                                            src={card.expansionSymbol}
                                            style={{ marginRight: 2 }}
                                        />
                                        {card.expansionName}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
