import { FC, useEffect, useState } from "react";
import { Page } from "../../components/Page";
import { useGetexpansionsQuery } from "../../features/expansions/expansionsApi";
import { useAppDispatch } from "../../app/store";
import { useTranslation } from "react-i18next";
import { setTitle } from "../../features/header/headerSlice";
import { setDocumentTitle } from "../../utils/setDocumentTitle";
import {
    Box,
    Card,
    CardContent,
    FormControl,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import { GetExpansionsResponse } from "@ekdennisek/pricetracker-api";

export const ExpansionsPage: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation("browseExpansionsPage");
    const { data: expansions } = useGetexpansionsQuery();

    const [sorting, setSorting] = useState<"name" | "releaseDate">("name");

    useEffect(() => {
        dispatch(setTitle(t("title")));
        setDocumentTitle(t("title"));
    }, [dispatch, t]);

    const sort = (a: GetExpansionsResponse[0], b: GetExpansionsResponse[0]) => {
        return a[sorting].localeCompare(b[sorting]);
    };

    return (
        <Page>
            <Stack spacing={1}>
                <FormControl>
                    <InputLabel>{t("sorting")}</InputLabel>
                    <Select
                        value={sorting}
                        onChange={(event) =>
                            setSorting(
                                event.target.value as "name" | "releaseDate"
                            )
                        }
                        label={t("sorting")}
                    >
                        <MenuItem value="name">{t("sortingName")}</MenuItem>
                        <MenuItem value="releaseDate">
                            {t("sortingReleaseDate")}
                        </MenuItem>
                    </Select>
                </FormControl>
                {(expansions ?? []).toSorted(sort).map((expansion) => (
                    <Card
                        key={expansion.expansionId}
                        onClick={() =>
                            navigate(`/expansion/${expansion.expansionId}`)
                        }
                        sx={{ cursor: "pointer" }}
                    >
                        <CardContent>
                            <Stack spacing={1}>
                                <LinearProgress
                                    value={
                                        (100 * (expansion.numOwned ?? 0)) /
                                        expansion.numTotal
                                    }
                                    variant="determinate"
                                />
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                    >
                                        <img
                                            src={expansion.symbol}
                                            style={{
                                                height: 24,
                                                marginRight: 6,
                                            }}
                                        />
                                        <Typography>
                                            {expansion.name}
                                        </Typography>
                                    </Box>
                                    <Typography>
                                        {expansion.numOwned ?? 0}/
                                        {expansion.numTotal}
                                    </Typography>
                                </Box>
                                <Typography>{expansion.releaseDate}</Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                ))}
            </Stack>
        </Page>
    );
};
