import { FC, useEffect, useState } from "react";
import { CollectionForm } from "./CollectionForm";
import { GetAccountCollectionsResponse } from "@ekdennisek/pricetracker-api";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useUpdateCollectionMutation } from "./collectionsApi";

type Props = {
    collection: GetAccountCollectionsResponse[0];
    onClose: () => void;
    open: boolean;
};

export const EditCollectionDialog: FC<Props> = ({
    collection,
    onClose,
    open,
}) => {
    const { t } = useTranslation("collectionsPage");
    const [updateCollection, updateCollectionMutation] =
        useUpdateCollectionMutation();

    const [collectionName, setCollectionName] = useState(
        collection.collectionName
    );
    const [publicVisibility, setPublicVisibility] = useState(
        collection.publicVisibility
    );

    useEffect(() => {
        setCollectionName(collection.collectionName);
        setPublicVisibility(collection.publicVisibility);
    }, [collection.collectionName, collection.publicVisibility]);

    const handleUpdate = async () => {
        try {
            await updateCollection({
                collectionId: collection.collectionId,
                collectionName,
                publicVisibility,
            });
            onClose();
        } catch (error) {
            alert(error);
        }
    };

    return (
        <Dialog fullWidth onClose={onClose} open={open}>
            <DialogTitle>{t("editCollectionDialog.title")}</DialogTitle>
            <DialogContent>
                <CollectionForm
                    collection={collection}
                    onChange={(arg) => {
                        setCollectionName(arg.collectionName);
                        setPublicVisibility(arg.publicVisibility);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    {t("editCollectionDialog.cancel")}
                </Button>
                <Button
                    disabled={updateCollectionMutation.isLoading}
                    onClick={handleUpdate}
                >
                    {t("editCollectionDialog.save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
