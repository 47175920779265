import {
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

type Props = {
    onItemClick: () => void;
};

export const Navigation: FC<Props> = ({ onItemClick }) => {
    const { t } = useTranslation("header");
    const navigate = useNavigate();

    const browseTo = (url: string) => {
        navigate(url);
        onItemClick();
    };

    return (
        <List>
            <ListItemButton onClick={() => browseTo("/")}>
                <ListItemIcon />
                <ListItemText>{t("navigation.home")}</ListItemText>
            </ListItemButton>
            <ListItemButton onClick={() => browseTo("/cards")}>
                <ListItemIcon />
                <ListItemText>{t("navigation.browseCards")}</ListItemText>
            </ListItemButton>
            <ListItemButton onClick={() => browseTo("/expansions")}>
                <ListItemIcon />
                <ListItemText>{t("navigation.browseExpansions")}</ListItemText>
            </ListItemButton>
        </List>
    );
};
