import { CardVariant } from "@ekdennisek/pricetracker-api";
import {
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetCollectionsQuery } from "../features/collections/collectionsApi";
import { conditionToEmoji } from "../utils/conditionToEmoji";

export const AddToCollectionDialog: FC<{
    onAdd: (details: {
        collectionId: number;
        cardVariant: CardVariant;
        condition: number;
        quantity: number;
    }) => void;
    onClose: () => void;
    open: boolean;
}> = ({ onAdd, onClose, open }) => {
    const { t } = useTranslation("addToCollectionDialog");

    const [selectedCollection, setSelectedCollection] = useState<number | "">();
    const [selectedCardVariant, setSelectedCardVariant] =
        useState<CardVariant>("standard");
    const [selectedCondition, setSelectedCondition] = useState<number>();
    const [selectedQuantity, setSelectedQuantity] = useState(0);

    const getCollectionsQuery = useGetCollectionsQuery();
    const collections = getCollectionsQuery.data;

    const handleClose = () => {
        setSelectedCollection(undefined);
        setSelectedCondition(undefined);
        setSelectedQuantity(0);
        onClose();
    };

    const handleAdd = () => {
        if (!selectedCollection || !selectedCondition || !selectedQuantity)
            return;
        onAdd({
            collectionId: selectedCollection,
            cardVariant: selectedCardVariant,
            condition: selectedCondition,
            quantity: selectedQuantity,
        });
        handleClose();
    };

    return (
        <Dialog fullWidth onClose={handleClose} open={open}>
            <DialogTitle>{t("title")}</DialogTitle>
            <DialogContent>
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel>{t("collection")}</InputLabel>
                    <Select
                        label={t("collection")}
                        onChange={(event) => {
                            const {
                                target: { value },
                            } = event;
                            setSelectedCollection(
                                typeof value === "string" ? undefined : value
                            );
                        }}
                        value={selectedCollection ?? ""}
                    >
                        {(collections ?? []).map((collection) => (
                            <MenuItem
                                key={collection.collectionId}
                                value={collection.collectionId}
                            >
                                {collection.collectionName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel>{t("cardVariant")}</InputLabel>
                    <Select
                        label={t("cardVariant")}
                        onChange={(event) =>
                            setSelectedCardVariant(
                                event.target.value as CardVariant
                            )
                        }
                        value={selectedCardVariant}
                    >
                        {(
                            [
                                "standard",
                                "first-edition",
                                "shadowless",
                                "reverse-holo",
                                "error",
                            ] as const
                        ).map((cardVariant) => (
                            <MenuItem key={cardVariant} value={cardVariant}>
                                {t(`cardVariant:${cardVariant}`)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mt: 1 }}>
                    <Typography fontWeight={500}>{t("condition")}</Typography>
                    <ButtonGroup fullWidth>
                        {[1, 2, 3, 4, 5].map((condition) => (
                            <Button
                                color={
                                    condition === selectedCondition
                                        ? "secondary"
                                        : "primary"
                                }
                                key={condition}
                                onClick={() => setSelectedCondition(condition)}
                                variant="contained"
                            >
                                {conditionToEmoji(condition)}
                            </Button>
                        ))}
                    </ButtonGroup>
                </FormControl>
                <FormControl fullWidth sx={{ mt: 3 }}>
                    <TextField
                        label={t("quantity")}
                        type="number"
                        onChange={(event) =>
                            setSelectedQuantity(
                                parseInt(event.target.value, 10)
                            )
                        }
                        value={selectedQuantity}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t("cancel")}</Button>
                <Button
                    disabled={
                        !selectedCollection ||
                        !selectedCondition ||
                        !selectedQuantity
                    }
                    onClick={handleAdd}
                >
                    {t("add")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
