import { Grid } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useAppDispatch } from "../../app/store";
import { Page } from "../../components/Page";
import { selectIsAuthenticated } from "../../features/account/accountSlice";
import { useGetCardQuery } from "../../features/card/cardApi";
import { setTitle } from "../../features/header/headerSlice";
import { CardCollections } from "./CardCollections";
import { CardSummary } from "./CardSummary";
import { OwnedByMe } from "./OwnedByMe";
import { setDocumentTitle } from "../../utils/setDocumentTitle";

export const CardPage: FC = () => {
    const dispatch = useAppDispatch();
    const { cardId } = useParams();

    const isAuthenticated = useSelector(selectIsAuthenticated);
    const { data: card } = useGetCardQuery(cardId ?? skipToken);

    useEffect(() => {
        dispatch(setTitle(card?.cardName ?? ""));
        setDocumentTitle(card?.cardName ?? "");
    }, [card?.cardName, dispatch]);

    if (!cardId || !card) return null;

    return (
        <Page>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <CardSummary card={card} />
                </Grid>
                <Grid item xs={12} md={6}>
                    {isAuthenticated && (
                        <OwnedByMe
                            cardId={cardId}
                            cardVariants={card.cardVariants}
                        />
                    )}
                </Grid>
                <Grid item xs={12}>
                    <CardCollections cardId={cardId} />
                </Grid>
            </Grid>
        </Page>
    );
};
