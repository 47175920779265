import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

type HeaderState = {
    title: string;
};

const initialState: HeaderState = {
    title: "Kortbanken",
};

const headerSlice = createSlice({
    name: "header",
    initialState,
    reducers: {
        setTitle(state, { payload }: PayloadAction<string>) {
            state.title = payload;
        },
    },
});

export default headerSlice.reducer;

export const { setTitle } = headerSlice.actions;

export const selectTitle = (state: RootState) => state.header.title;
