import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { translations } from "./translations";

export const resources = {
    "sv-SE": translations,
};

export function initI18n() {
    i18n.use(initReactI18next).init({
        debug: process.env.NODE_ENV === "development",
        lng: "sv-SE",
        ns: ["pageNotFound"],
        react: {
            useSuspense: false,
        },
        resources,
    });
}
