import { GetCardResponse } from "@ekdennisek/pricetracker-api";
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "../../components/Link";

export const CardSummary: FC<{ card: NonNullable<GetCardResponse> }> = ({
    card,
}) => {
    const { t } = useTranslation("cardPage");

    return (
        <Card sx={{ height: "100%", padding: 1 }}>
            <CardMedia
                component="img"
                image={card.cardImage}
                sx={{
                    height: 400,
                    objectFit: "contain",
                }}
            />
            <CardContent>
                <Typography gutterBottom variant="h6">
                    {card.cardName}
                </Typography>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell variant="head">
                                {t("cardDetails.cardNumber")}
                            </TableCell>
                            <TableCell>
                                <Typography>{card.cardNumber}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">
                                {t("cardDetails.expansionName")}
                            </TableCell>
                            <TableCell>
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    flexDirection="row"
                                >
                                    <Link
                                        href={`/expansion/${card.expansionId}`}
                                    >
                                        <img
                                            alt={card.expansionName}
                                            height={16}
                                            src={card.expansionSymbol}
                                            style={{ marginRight: 2 }}
                                        />
                                        <Typography>
                                            {card.expansionName}
                                        </Typography>
                                    </Link>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};
