import { CardVariant } from "@ekdennisek/pricetracker-api";
import {
    Box,
    Button,
    Card,
    CardContent,
    Stack,
    Typography,
} from "@mui/material";
import { FC } from "react";
import { useParams } from "react-router";
import { AddToCollectionDialog } from "../../components/AddToCollectionDialog";
import { Link } from "../../components/Link";
import { Page } from "../../components/Page";
import { useAddCardToCollectionMutation } from "../../features/collections/collectionsApi";
import { useGetExpansionQuery } from "../../features/expansions/expansionsApi";
import { useDialog } from "../../utils/useDialog";

export const ExpansionPage: FC = () => {
    const addAllDialog = useDialog();
    const { expansionId } = useParams<{ expansionId: string }>();

    const [addCardToCollection] = useAddCardToCollectionMutation();
    const { data: expansion } = useGetExpansionQuery(expansionId as string, {
        skip: expansionId === undefined,
    });

    const handleAddToCollection = async ({
        collectionId,
        cardVariant,
        condition,
        quantity,
    }: {
        collectionId: number;
        cardVariant: CardVariant;
        condition: number;
        quantity: number;
    }) => {
        if (!expansion) return;

        await addCardToCollection({
            cardIds: expansion.cards.map((card) => card.cardId),
            collectionId,
            cardVariant,
            condition,
            quantity,
        });
    };

    return (
        <Page>
            <Card>
                <CardContent>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <img
                            src={expansion?.symbol}
                            alt="todo expansion symbol"
                        />
                        <Typography sx={{ ml: 1 }}>
                            {expansion?.name}
                        </Typography>
                    </Box>
                    <Typography>{expansion?.releaseDate}</Typography>
                    <Button
                        onClick={addAllDialog.open}
                        variant="contained"
                        sx={{ mt: 1 }}
                    >
                        todo lägg till alla kort i samling
                    </Button>
                    <AddToCollectionDialog
                        onAdd={handleAddToCollection}
                        onClose={addAllDialog.close}
                        open={addAllDialog.isOpen}
                    />
                </CardContent>
            </Card>
            <Stack spacing={1} sx={{ mt: 2 }}>
                {(expansion?.cards ?? []).map((card) => (
                    <Link
                        href={`/card/${card.cardId}/${encodeURIComponent(
                            card.name
                        )}`}
                        sx={{ textDecoration: "none" }}
                    >
                        <Card
                            sx={{
                                flexGrow: 1,
                                backgroundColor: card.inInventory
                                    ? "#97c489"
                                    : undefined,
                            }}
                        >
                            <CardContent sx={{ p: "16px !important" }}>
                                {card.name} {card.number}/
                                {expansion?.printedTotal}
                            </CardContent>
                        </Card>
                    </Link>
                ))}
            </Stack>
        </Page>
    );
};
