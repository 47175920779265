import { Photo } from "@mui/icons-material";
import {
    Box,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../app/store";
import { Link } from "../../components/Link";
import { Page } from "../../components/Page";
import { conditionToEmoji } from "../../utils/conditionToEmoji";
import {
    useGetInventoryCardsQuery,
    useGetInventoryExpansionsQuery,
} from "../collections/collectionsApi";
import { setTitle } from "../header/headerSlice";
import { setDocumentTitle } from "../../utils/setDocumentTitle";

export const InventoryPage: FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation(["cardVariant", "inventoryPage"]);

    const [page, setPage] = useState(1);
    const [selectedExpansions, setSelectedExpansions] = useState<string[]>([]);

    const { data: inventoryExpansions } = useGetInventoryExpansionsQuery();
    const { data: inventoryCards } = useGetInventoryCardsQuery({
        expansions: selectedExpansions.length ? selectedExpansions : undefined,
        page,
    });

    useEffect(() => {
        dispatch(setTitle(t("inventoryPage:title")));
        setDocumentTitle(t("inventoryPage:title"));
    }, [dispatch, t]);

    return (
        <Page>
            <FormControl sx={{ m: 1 }}>
                <InputLabel>{t("inventoryPage:filters.expansions")}</InputLabel>
                <Select
                    input={
                        <OutlinedInput
                            label={t("inventoryPage:filters.expansions")}
                        />
                    }
                    MenuProps={{ PaperProps: { style: { width: 250 } } }}
                    multiple
                    onChange={(event) => {
                        const value = event.target.value;
                        setSelectedExpansions(
                            typeof value === "string" ? [] : value
                        );
                    }}
                    renderValue={(selectedOptions) =>
                        selectedOptions
                            .map(
                                (selectedOption) =>
                                    (inventoryExpansions ?? []).find(
                                        (e) => e.expansionId === selectedOption
                                    )?.expansionName
                            )
                            .join(", ")
                    }
                    sx={{ minWidth: 250 }}
                    value={selectedExpansions}
                >
                    {(inventoryExpansions ?? []).map((expansion) => (
                        <MenuItem
                            key={expansion.expansionId}
                            value={expansion.expansionId}
                        >
                            <Checkbox
                                checked={selectedExpansions.includes(
                                    expansion.expansionId
                                )}
                            />
                            <ListItemText primary={expansion.expansionName} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {t("inventoryPage:table.image")}
                            </TableCell>
                            <TableCell>
                                {t("inventoryPage:table.cardName")}
                            </TableCell>
                            <TableCell>
                                {t("inventoryPage:table.cardNumber")}
                            </TableCell>
                            <TableCell>
                                {t("inventoryPage:table.expansionName")}
                            </TableCell>
                            <TableCell>
                                {t("inventoryPage:table.cardVariant")}
                            </TableCell>
                            <TableCell>
                                {t("inventoryPage:table.condition")}
                            </TableCell>
                            <TableCell>
                                {t("inventoryPage:table.quantity")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(inventoryCards ?? []).map((card) => (
                            <TableRow
                                key={`${card.cardId}-${card.cardCondition}`}
                            >
                                <TableCell>
                                    <Tooltip
                                        title={
                                            <img
                                                alt={card.cardName}
                                                src={card.cardImage}
                                            />
                                        }
                                    >
                                        <Photo />
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    <Link
                                        href={`/card/${
                                            card.cardId
                                        }/${encodeURIComponent(card.cardName)}`}
                                    >
                                        {card.cardName}
                                    </Link>
                                </TableCell>
                                <TableCell>{card.cardNumber}</TableCell>
                                <TableCell>
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        flexDirection="row"
                                    >
                                        <img
                                            alt={card.expansionName}
                                            height={16}
                                            src={card.expansionSymbol}
                                            style={{ marginRight: 2 }}
                                        />
                                        {card.expansionName}
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    {t(`cardVariant:${card.cardVariant}`)}
                                </TableCell>
                                <TableCell>
                                    {conditionToEmoji(card.cardCondition)}
                                </TableCell>
                                <TableCell>{card.cardQuantity}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Page>
    );
};
