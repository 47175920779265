import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { Search } from "@mui/icons-material";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router";

export const SearchField: FC = () => {
    const navigate = useNavigate();

    const [input, setInput] = useState("");

    const handleSearch = () => {
        navigate(`/cards?q=${input}`);
    };

    return (
        <OutlinedInput
            endAdornment={
                <InputAdornment position="end">
                    <IconButton onClick={handleSearch}>
                        <Search />
                    </IconButton>
                </InputAdornment>
            }
            onChange={(event) => setInput(event.target.value)}
            onKeyDown={(event) => {
                if (event.key === "Enter") handleSearch();
            }}
            size="small"
            value={input}
        />
    );
};
