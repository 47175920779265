import { LoginRequestBody, LoginResponse } from "@ekdennisek/pricetracker-api";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { post } from "../../utils/restApi";

type AccountState = {
    jwt?: string;
    accountId?: number;
};

const initialState: AccountState = {};

export const login = createAsyncThunk("account/login", async (code: string) =>
    post<LoginResponse>("/account/login", { code } satisfies LoginRequestBody)
);

export const logout = createAsyncThunk("account/logout", async () =>
    post("/account/logout")
);

// TODO Rewrite usign RTK Query
const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        setJwt(state, { payload }: PayloadAction<string>) {
            state.jwt = payload;
        },
        setAccountId(state, { payload }: PayloadAction<number>) {
            state.accountId = payload;
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(login.fulfilled, (state, { payload: { jwt, accountId } }) => {
            state.jwt = jwt;
            state.accountId = accountId;
            localStorage.setItem("jwt", jwt);
            localStorage.setItem("accountId", accountId.toString());
        });
        addCase(logout.fulfilled, (state) => {
            state.jwt = undefined;
            state.accountId = undefined;
            localStorage.removeItem("jwt");
            localStorage.removeItem("accountId");
        });
        addCase(logout.rejected, (state) => {
            state.jwt = undefined;
            state.accountId = undefined;
            localStorage.removeItem("jwt");
            localStorage.removeItem("accountId");
        });
    },
});

export const selectIsAuthenticated = (state: RootState) =>
    state.account.jwt !== undefined;

export default accountSlice.reducer;

export const { setJwt, setAccountId } = accountSlice.actions;
