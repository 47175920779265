import {
    CardVariant,
    GetAccountCollectionsResponse,
    GetCollectionDetailsResponse,
    GetCollectionsWithCardInItResponse,
    GetInventoryCardResponse,
    GetInventoryCardsResponse,
    GetInventoryExpansionsResponse,
    UpdateCollectionRequestBody,
} from "@ekdennisek/pricetracker-api";
import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../utils/restApi";

export const collectionsApi = createApi({
    reducerPath: "collectionsApi",
    baseQuery: axiosBaseQuery({
        baseUrl: "/collections",
    }),
    tagTypes: ["Collection", "CollectionCard", "Inventory", "InventoryCard"],
    endpoints: (builder) => ({
        // Collections
        addCardToCollection: builder.mutation<
            void,
            {
                collectionId: number;
                cardIds: string[];
                cardVariant: CardVariant;
                condition: number;
                quantity: number;
            }
        >({
            query: ({
                cardIds,
                collectionId,
                cardVariant,
                condition,
                quantity,
            }) => ({
                url: `/${collectionId}`,
                method: "POST",
                data: {
                    cardIds,
                    cardVariant,
                    condition,
                    quantity,
                },
            }),
            invalidatesTags: (_, __, { collectionId }) => [
                { type: "Collection", id: collectionId },
                { type: "CollectionCard", id: "LIST" },
            ],
        }),
        createCollection: builder.mutation<
            void,
            { collectionName: string; publicVisibility: boolean }
        >({
            query: (data) => ({
                url: "/",
                method: "POST",
                data,
            }),
            invalidatesTags: [{ type: "Collection", id: "LIST" }],
        }),
        deleteCardFromCollection: builder.mutation<
            void,
            { collectionId: number; cardId: string; condition: number }
        >({
            query: ({ collectionId, cardId, condition }) => ({
                url: `/${collectionId}/${cardId}/${condition}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, __, { cardId, condition }) => [
                { type: "CollectionCard", id: `${cardId}-${condition}` },
                { type: "CollectionCard", id: "LIST" },
            ],
        }),
        deleteCollection: builder.mutation<void, number>({
            query: (collectionId) => ({
                url: `/${collectionId}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, __, collectionId) => [
                { type: "Collection", id: collectionId },
            ],
        }),
        getCollection: builder.query<GetCollectionDetailsResponse, number>({
            query: (collectionId) => ({
                url: `/${collectionId}`,
                method: "GET",
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.cards.map(
                              (card) =>
                                  ({
                                      type: "CollectionCard",
                                      id: `${card.cardId}-${card.condition}`,
                                  } as const)
                          ),
                          { type: "CollectionCard", id: "LIST" },
                      ]
                    : [{ type: "CollectionCard", id: "LIST" }],
        }),
        getCollections: builder.query<GetAccountCollectionsResponse, void>({
            query: () => ({
                url: "",
                method: "GET",
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(
                              (collection) =>
                                  ({
                                      type: "Collection",
                                      id: collection.collectionId,
                                  } as const)
                          ),
                          { type: "Collection", id: "LIST" },
                      ]
                    : [{ type: "Collection", id: "LIST" }],
        }),
        getCollectionsWithCardInIt: builder.query<
            GetCollectionsWithCardInItResponse,
            string
        >({
            query: (cardId) => ({
                url: `/collections/card/${cardId}`,
                method: "GET",
            }),
            providesTags: [{ type: "CollectionCard", id: "LIST" }],
        }),
        updateCollection: builder.mutation<
            void,
            UpdateCollectionRequestBody & { collectionId: number }
        >({
            query: ({ collectionId, collectionName, publicVisibility }) => ({
                url: `/${collectionId}/update`,
                method: "POST",
                data: { collectionName, publicVisibility },
            }),
            invalidatesTags: () => [{ type: "Collection", id: "LIST" }],
        }),
        // Inventory
        getInventoryCard: builder.query<GetInventoryCardResponse, string>({
            query: (cardId) => ({
                url: `/inventory/${cardId}`,
                method: "GET",
            }),
            providesTags: [{ type: "InventoryCard", id: "CARD" }],
        }),
        getInventoryCards: builder.query<
            GetInventoryCardsResponse,
            { expansions?: string[]; page: number }
        >({
            query: ({ expansions, page }) => ({
                url: "/inventory",
                method: "GET",
                params: {
                    expansions: expansions?.join(","),
                    page,
                },
            }),
            providesTags: [{ type: "Inventory", id: "LIST" }],
        }),
        getInventoryExpansions: builder.query<
            GetInventoryExpansionsResponse,
            void
        >({
            query: () => ({
                url: "/inventory/expansions",
                method: "GET",
            }),
        }),
        updateInventoryQuantity: builder.mutation<
            void,
            {
                cardId: string;
                cardVariant: CardVariant;
                condition: number;
                quantity: number;
            }
        >({
            query: (arg) => ({
                url: "/inventory/update-quantity",
                method: "PUT",
                data: arg,
            }),
            invalidatesTags: [
                { type: "Collection", id: "LIST" },
                { type: "Inventory", id: "LIST" },
                { type: "InventoryCard", id: "CARD" },
            ],
        }),
    }),
});

export const {
    useAddCardToCollectionMutation,
    useCreateCollectionMutation,
    useDeleteCardFromCollectionMutation,
    useDeleteCollectionMutation,
    useGetCollectionQuery,
    useGetCollectionsQuery,
    useGetCollectionsWithCardInItQuery,
    useGetInventoryCardQuery,
    useGetInventoryCardsQuery,
    useGetInventoryExpansionsQuery,
    useUpdateCollectionMutation,
    useUpdateInventoryQuantityMutation,
} = collectionsApi;
