import {
    GetExpansionResponse,
    GetExpansionsResponse,
} from "@ekdennisek/pricetracker-api";
import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../utils/restApi";

export const expansionsApi = createApi({
    reducerPath: "expansionsApi",
    baseQuery: axiosBaseQuery({
        baseUrl: "/expansions",
    }),
    endpoints: (builder) => ({
        getexpansions: builder.query<GetExpansionsResponse, void>({
            query: () => ({
                url: "/",
                method: "GET",
            }),
        }),
        getExpansion: builder.query<GetExpansionResponse, string>({
            query: (expansionId) => ({
                url: `/${expansionId}`,
                method: "GET",
            }),
        }),
    }),
});

export const { useGetexpansionsQuery, useGetExpansionQuery } = expansionsApi;
