import { Box, Button, Stack, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../app/store";
import { Page } from "../../components/Page";
import { setDocumentTitle } from "../../utils/setDocumentTitle";
import { setTitle } from "../header/headerSlice";
import { Collection } from "./Collection";
import { CreateCollectionDialog } from "./CreateCollectionDialog";
import { useGetCollectionsQuery } from "./collectionsApi";

export const CollectionsPage: FC = () => {
    const { t } = useTranslation("collectionsPage");
    const dispatch = useAppDispatch();

    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const { data: collections } = useGetCollectionsQuery();

    useEffect(() => {
        dispatch(setTitle(t("title")));
        setDocumentTitle(t("title"));
    }, [dispatch, t]);

    return (
        <Page>
            <Box
                sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    mb: 1,
                }}
            >
                <Typography variant="h4">{t("title")}</Typography>
                <Button
                    onClick={() => setDialogIsOpen(true)}
                    variant="contained"
                >
                    {t("createCollection")}
                </Button>
            </Box>
            <CreateCollectionDialog
                onClose={() => setDialogIsOpen(false)}
                open={dialogIsOpen}
            />
            <Stack spacing={1}>
                {(collections ?? []).map((collection) => (
                    <Collection
                        key={collection.collectionId}
                        collection={collection}
                    />
                ))}
            </Stack>
        </Page>
    );
};
