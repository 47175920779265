import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { useAppDispatch } from "../../app/store";
import { LoginButton } from "../../components/LoginButton";
import { Page } from "../../components/Page";
import { setDocumentTitle } from "../../utils/setDocumentTitle";
import { setTitle } from "../header/headerSlice";
import { selectIsAuthenticated } from "./accountSlice";

export const LoginPage: FC = () => {
    const { t } = useTranslation("loginPage");
    const dispatch = useAppDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);

    useEffect(() => {
        dispatch(setTitle(t("title")));
        setDocumentTitle(t("title"));
    }, [dispatch, t]);

    if (isAuthenticated) {
        return <Navigate to="/" />;
    }

    return (
        <Page>
            <LoginButton />
        </Page>
    );
};
