import { CardVariant } from "@ekdennisek/pricetracker-api";
import {
    Box,
    Button,
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { AddToCollectionDialog } from "../../components/AddToCollectionDialog";
import { Link } from "../../components/Link";
import {
    useAddCardToCollectionMutation,
    useGetCollectionsWithCardInItQuery,
} from "../../features/collections/collectionsApi";
import { conditionToEmoji } from "../../utils/conditionToEmoji";

export const CardCollections: FC<{
    cardId: string;
}> = ({ cardId }) => {
    const { t } = useTranslation("cardPage");

    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    const [addCardToCollection] = useAddCardToCollectionMutation();
    const getCollectionsWithCardInIt =
        useGetCollectionsWithCardInItQuery(cardId);
    const collections = getCollectionsWithCardInIt.data;

    if (!collections) return null;

    const handleAdd = async ({
        collectionId,
        cardVariant,
        condition,
        quantity,
    }: {
        collectionId: number;
        cardVariant: CardVariant;
        condition: number;
        quantity: number;
    }) => {
        await addCardToCollection({
            cardIds: [cardId],
            collectionId,
            cardVariant,
            condition,
            quantity,
        });
    };

    return (
        <Card>
            <CardContent>
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                >
                    <Typography gutterBottom variant="h6">
                        {t("collectionsWithCardInIt.title")}
                    </Typography>
                    <Button onClick={() => setDialogIsOpen(true)}>
                        {t("collectionsWithCardInIt.addToCollection")}
                    </Button>
                    <AddToCollectionDialog
                        onAdd={handleAdd}
                        onClose={() => setDialogIsOpen(false)}
                        open={dialogIsOpen}
                    />
                </Box>
                {collections.length ? (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {t(
                                            "collectionsWithCardInIt.table.collection"
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {t(
                                            "collectionsWithCardInIt.table.cardVariant"
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {t(
                                            "collectionsWithCardInIt.table.condition"
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {t(
                                            "collectionsWithCardInIt.table.quantity"
                                        )}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {collections.map((item) => (
                                    <TableRow
                                        key={`${item.collectionId}-${item.cardVariant}-${item.condition}`}
                                    >
                                        <TableCell>
                                            <Link
                                                href={`/collections/${item.collectionId}`}
                                            >
                                                {item.collectionName}
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            {t(
                                                `cardVariant:${item.cardVariant}`
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {conditionToEmoji(item.condition)}
                                        </TableCell>
                                        <TableCell>{item.quantity}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography>
                        {t("collectionsWithCardInIt.noCollectionsWithCardInIt")}
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};
