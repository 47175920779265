import { GetAccountCollectionsResponse } from "@ekdennisek/pricetracker-api";
import { MoreVert } from "@mui/icons-material";
import {
    Box,
    Card,
    CardContent,
    IconButton,
    LinearProgress,
    Menu,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "../../components/Link";
import { ConfirmDeleteDialog } from "./ConfirmDeleteDialog";
import { useDialog } from "../../utils/useDialog";
import { EditCollectionDialog } from "./EditCollectionDialog";

type Props = {
    collection: GetAccountCollectionsResponse[0];
};

export const Collection: FC<Props> = ({ collection }) => {
    const { t } = useTranslation("collectionsPage");

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    return (
        <Card key={collection.collectionId}>
            <CardContent>
                <Stack spacing={1}>
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography fontWeight={500}>
                            {collection.collectionName}
                        </Typography>
                        <IconButton
                            onClick={(event) =>
                                setAnchorEl(event.currentTarget)
                            }
                        >
                            <MoreVert />
                        </IconButton>
                        <CollectionMenu
                            collection={collection}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                        />
                    </Box>
                    <Box>
                        {collection.numCardsInCollection > 0 && (
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <LinearProgress
                                    value={
                                        (100 * collection.numCardsInInventory) /
                                        collection.numCardsInCollection
                                    }
                                    variant="determinate"
                                    sx={{
                                        height: 16,
                                        flexGrow: 1,
                                        borderRadius: "5px",
                                    }}
                                />
                                <Typography sx={{ pl: 1 }}>
                                    {collection.numCardsInInventory}/
                                    {collection.numCardsInCollection}
                                </Typography>
                            </Box>
                        )}
                        <Typography>
                            {t("publicVisibility.title")}:{" "}
                            {collection.publicVisibility
                                ? t("publicVisibility.public")
                                : t("publicVisibility.private")}
                        </Typography>
                    </Box>
                    <Link href={`/collections/${collection.collectionId}`}>
                        {t("showCollection")}
                    </Link>
                </Stack>
            </CardContent>
        </Card>
    );
};

const CollectionMenu: FC<{
    collection: GetAccountCollectionsResponse[0];
    anchorEl: HTMLElement | null;
    onClose: () => void;
}> = ({ collection, anchorEl, onClose }) => {
    const { t } = useTranslation("collectionsPage");
    const confirmDeleteDialog = useDialog();
    const editDialog = useDialog();

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                }}
                onClose={onClose}
                open={Boolean(anchorEl)}
                transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                }}
            >
                <MenuItem
                    onClick={() => {
                        onClose();
                        editDialog.open();
                    }}
                >
                    {t("editCollection")}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        onClose();
                        confirmDeleteDialog.open();
                    }}
                >
                    {t("deleteCollection")}
                </MenuItem>
            </Menu>
            <ConfirmDeleteDialog
                collection={collection}
                onClose={confirmDeleteDialog.close}
                open={confirmDeleteDialog.isOpen}
            />
            <EditCollectionDialog
                collection={collection}
                onClose={editDialog.close}
                open={editDialog.isOpen}
            />
        </>
    );
};
