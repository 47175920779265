import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "../../utils/restApi";
import { AccountSummary } from "@ekdennisek/pricetracker-api";

export const accountApi = createApi({
    reducerPath: "accountApi",
    baseQuery: axiosBaseQuery({
        baseUrl: "/account",
    }),
    endpoints: (builder) => ({
        getSummary: builder.query<AccountSummary, void>({
            query: () => ({
                url: "/summary",
                method: "GET",
            }),
        }),
    }),
});

export const { useGetSummaryQuery } = accountApi;
