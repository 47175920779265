import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteCollectionMutation } from "./collectionsApi";

type Props = {
    collection:
        | {
              collectionId: number;
              collectionName: string;
          }
        | undefined;
    onClose: () => void;
    open: boolean;
};

export const ConfirmDeleteDialog: FC<Props> = ({
    collection,
    onClose,
    open,
}) => {
    const { t } = useTranslation("collectionsPage");

    const [deleteCollection] = useDeleteCollectionMutation();

    if (!collection) return null;

    const handleDelete = async () => {
        await deleteCollection(collection.collectionId);
        onClose();
    };

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>{t("confirmDeleteDialog.title")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("confirmDeleteDialog.text", {
                        collectionName: collection.collectionName,
                    })}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    {t("confirmDeleteDialog.cancel")}
                </Button>
                <Button onClick={handleDelete}>
                    {t("confirmDeleteDialog.delete")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
